import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Divider} from 'primereact/divider';
import {classNames} from 'primereact/utils';
import UserService from '../services/UserService';
import {useTranslation} from 'react-i18next';
import 'primeflex/primeflex.min.css';
import 'primereact/resources/primereact.min.css';
import '../assets/css/Form.css';
import {RECAPTCHA_SITE_KEY} from '../constants/Constants';
import {Captcha} from 'primereact/captcha';
import LanguageSelect from "../languageSelect";

export const Register = () => {
	const { t } = useTranslation();
	const [displayBasic, setDisplayBasic] = useState(false);
	const [headerText, setHeaderText] = useState([]);
	const [captchaVerified, setCaptchaVerified] = useState(null);
	const [dissabledBtn, setDissabledBtn] = useState(false);
	const defaultValues = {
		fullName: '',
		firstName: '',
		midName: '',
		lastName: '',
		mail: '',
		phone: '',
		company: ''
	};
	const showResponse = (response) => {
		setCaptchaVerified(response);
	}

	const [statusImage, setStatusImage] = useState(null);
	const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
	const onHide = (name) => {
		dialogFuncMap[`${name}`](false);
	};

	const onSubmit = async (data) => {
		if (captchaVerified) {
			setDissabledBtn(true);
			const count = data.fullName.split(" ").length;

			data.fullName.split(" ").map((item, index) => {
				switch (index) {
					case 0 : {
						data.firstName = item;
					}
					case 1 : {
						if (count === 3)
							data.midName = item;
						else {
							data.midName = '.';
							data.lastName = item;
						}
					}
					case 2 :
						data.lastName = item;
				}
			});
			const userService = new UserService();
			await userService.registerUser(data)
				.then((response) => {
					setHeaderText(t("registration_success"));
					setStatusImage("assets/layout/images/transaction_successful.gif");
					reset();
				})
				.catch((error) => {
					console.log(JSON.stringify(error));
					setHeaderText(t("registration_error"));
					setStatusImage("assets/layout/images/transaction_failed.gif");
				});
			dialogFuncMap['displayBasic'](true);
			setDissabledBtn(false);
		}
	};
	const dialogFuncMap = {
		'displayBasic': setDisplayBasic
	}

	const getFormErrorMessage = (name) => {
		return errors[name] && <small className="p-error">{errors[name].message}</small>
	};

	const passwordHeader = <h6>Pick a password</h6>;
	const passwordFooter = (
		<React.Fragment>
			<Divider />
			<p className="p-mt-2">Suggestions</p>
			<ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: '1.5' }}>
				<li>At least one lowercase</li>
				<li>At least one uppercase</li>
				<li>At least one numeric</li>
				<li>Minimum 8 characters</li>
			</ul>
		</React.Fragment>
	);

	return (
		<div className="form-register">
			<div className="p-d-flex p-jc-center">
				<div className="card">
					<div className="text-center mb-5">
						<img src={t('logo_vertical_url')} alt="Транспаранси" height={250} className="mb-3" />
						<div className="text-900 text-2xl font-medium mb-3">{t('registration')}</div>
						<span className="guest-sign-in">{t("registration_form_head_message")}</span>
						<hr/>
						<a className="font-medium no-underline ml-2 apply-button cursor-pointer line-height-3" href="/login">{t('sign_in')}</a>
					</div>
					<form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
						<div className="p-field width94">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-user" />
                                <Controller name="fullName" control={control} rules={{ required: t('name_required') }}
											render={({ field,
														 fieldState }) => (
										<InputText id={field.name} {...field} autoFocus
												   className={classNames({ 'p-invalid': fieldState.invalid })} />
									)} />
                                <label htmlFor="fullName" className={classNames({ 'p-error': errors.name })}>{t('name')}*</label>
                            </span>
							{getFormErrorMessage('fullName')}
						</div>
						<div className="p-field width94">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <Controller name="mail" control={control}
											rules={{ required: t('email_required'), pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
													message: 'Invalid email address. E.g. example@email.com' }}}
											render={({ field, fieldState }) => (
												<InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} keyfilter="email"/>
											)} />
                                <label htmlFor="mail" className={classNames({ 'p-error': !!errors.email })}>{t('email')}*</label>
                            </span>
							{getFormErrorMessage('mail')}
						</div>
						<div className="p-field width94">
                            <span className="p-float-label p-input-icon-right">
									<i className="pi pi-phone" />
                                <Controller name="phone" control={control} rules={{ required: t('phone_required') }} render={({ field, fieldState }) => (
									<InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} keyfilter="num" />
								)} />
                                <label htmlFor="phone" className={classNames({ 'p-error': errors.name })}>{t('phone')}*</label>
                            </span>
							{getFormErrorMessage('phone')}
						</div>
						<div className="p-field width94">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-home" />
                                <Controller name="company" control={control} rules={{ required: t('company_required') }}
											render={({ field, fieldState }) => (
									<InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
								)} />
                                <label htmlFor="company" className={classNames({ 'p-error': errors.name })}>{t('company')}*</label>
                            </span>
							{getFormErrorMessage('company')}
						</div>

						<div className="recaptcha" style={{textAlign: "center;"}}>
							<Captcha siteKey={RECAPTCHA_SITE_KEY} onResponse={showResponse} style={{margin: "auto"}}/>
						</div>
						<div style={{height: "25px"}}/>
						<div className="p-grid-page p-fluid">
							<div className="one-of-tree-column">
								<div style={{marginLeft: "1rem"}}>
									<a className="text-600 font-medium no-underline cursor-pointer line-height-3" href="/forgotPassword">{t('forgot_password')}</a>
									<div style={{height: "25px"}}/>
									<label>{t("useful_links")} :</label>
									<ol style={{marginLeft: "-25px"}}>
										<li>
											<a href="https://business.transparency.bg/" title={t("business_integrity")}>{t("business_integrity")}</a>
										</li>
										<li>
											<a href="https://business.transparency.bg/цели-на-клуба/" title={t("mission")}>{t("mission")}</a>
										</li>
										<li>
											<a href="https://business.transparency.bg/предимства-от-участието/" title={t("why_us")}>{t("why_us")}</a>
										</li>
										<li>
											<a href="https://business.transparency.bg/как-да-стана-член/" title={t("how_to_join")}>{t("how_to_join")}</a>
										</li>
										<li>
											<a href="https://business.transparency.bg/правила-на-клуба/" title={t("rules")}>{t("rules")}</a>
										</li>
									</ol>
									<div style={{width: "30%", float: "left"}}>
										<LanguageSelect showText="false"/>
									</div>
								</div>
							</div>

							<div className="one-of-tree-column" style={{textAlign:"center"}}>
								<Button type="submit" label={t('join')} icon="pi pi-check" className="p-button-success" style={{width:"100%", borderRadius:0}}/>
								<br/>
								<br/>
								<a href="https://apps.apple.com/us/app/tibackbone/id1590525153" target="_blank">
									<img src="/assets/layout/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" width="100%"/>
								</a>
								<a href="https://play.google.com/store/apps/details?id=ltd.happycompany.tibackbone" target="_blank">
									<img src="/assets/layout/images/download-for-android.jpeg" width="100%"/>
								</a>
							</div>
						</div>

					</form>
				</div>
			</div>
			<Dialog header={headerText} visible={displayBasic}
					footer="  " style={{ width: '40vw', textAlign: 'center'}} onHide={() => onHide('displayBasic')}>
				<img src={statusImage} width="70%"/>
			</Dialog>
		</div>
	);
}