import React, {useEffect, useRef, useState} from 'react';
import {FilterMatchMode, FilterOperator} from 'primereact/api';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {MultiSelect} from 'primereact/multiselect';
import FileService from '../services/FileService';
import UserService from '../services/UserService';
import dateFormat from 'dateformat';
import QRCode from 'react-qr-code';
import {Dialog} from 'primereact/dialog';
import {DATE_FORMAT} from '../constants/Constants';
import {saveAs} from "file-saver";
import {useTranslation} from 'react-i18next';
import authenticationService from '../services/auth.service';

export const Dashboard = props => {

    const {t} = useTranslation();
    let emptyFile = {
        fileId: null,
        name: '',
        documentPDF: null,
        createdOn: '',
        category: new Date(),
        blockRef: '',
        userFileReadList: [],
        userFileSignList: []
    };
    const [fileList, setFileList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedRepresentative, setSelectedRepresentative] = useState(null);
    const [file, setFile] = useState(emptyFile);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    // const [globalFilter, setGlobalFilter] = useState('');
    const [fileSignDialog, setFileSignDialog] = useState(false);
    const [fileDetDialog, setFileDetDialog] = useState(false);
    const [filters, setFilters] = useState(null);
    const dt = useRef(null);
    const fdt = useRef(null);

    const statuses = [
        'signed', 'notsigned', 'notforsign'
    ];

    useEffect(() => {
        const userService = new UserService();
        userService.getUserList(authenticationService.getCurrentUser().userId)
            .then(response => setUserList(response.data));
    }, []);

    useEffect(() => {
        const fileService = new FileService();
        fileService.getFileList(authenticationService.getCurrentUser().userId)
            .then(response => setFileList(response.data));
        initFilters();
    }, []);

    function LoadFileList() {
        const fileService = new FileService();
        fileService.getFileList(authenticationService.getCurrentUser().userId)
            .then(response => setFileList(response.data));
    }

    const filterDate = (value, filter) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }

        return value === formatDate(filter);
    }
    const initFilters = () => {
        setFilters({
            'fileCreatedOn': { operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }] },
            'fileName': { operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
            'fileUserId': { operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.EXACT }] },
            'status': { operator: FilterOperator.AND,
                constraints: [{ value: null, matchMode: FilterMatchMode.EXACT }] }
        });
    }
    const formatDate = (date) => {
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return date.getFullYear() + '-' + month + '-' + day;
    }

    const onRepresentativesChange = (e) => {
        dt.current.filter(e.value, 'fileUserId', 'in');
        setSelectedRepresentative(e.value);
    }

    const onDateChange = (e) => {
        console.log(e.value);
        let fromDate = e.value[0];
        let toDate = e.value[1];
        if (toDate){
            toDate.setHours(23);
            toDate.setMinutes(59);
            toDate.setSeconds(59);
        }
        dt.current.filter(e.value, 'date', 'between');
        setSelectedDate(e.value);
    }

    const onStatusChange = (e) => {
        // console.log("e : " + e);
        // console.log("e.value : " + e.value);
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatus(e.value);
    }

    const representativeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title">{t('representative')}</span>*/}
                {/*<img alt={rowData.representative.name} src={`showcase/demo/images/avatar/${rowData.representative.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width={32} style={{verticalAlign: 'middle'}} />*/}
                <span className="image-text">{rowData.fileUserFirstLastName}</span>
            </React.Fragment>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title">{t('date')}</span>*/}
                <span>{dateFormat(rowData.fileCreatedOn, DATE_FORMAT)}</span>
            </React.Fragment>
        );
    }

    const statusBodyTemplate = (rowData) => {
        let color;
        if (rowData.signAllowed){ // When you have rights to sign
            color = (rowData.signTransactionId !== null) ? 'green' : 'red';
            rowData.status = (rowData.signTransactionId !== null) ? 'signed' : 'notsigned';
        } else { // When you do NOT have rights to sign
            color = 'black';
            rowData.status = 'notforsign';
        }
        return (
            <React.Fragment>
                {/*<span className="p-column-title">{t('status')}</span>*/}
                <span className={`customer-badge status-${rowData.status}`}
                      style={{color: color}}>
                    {t(rowData.status)}
                </span>
            </React.Fragment>
        );
    }

    const representativesItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                {/*<img alt={option.name} src={`showcase/demo/images/avatar/${option.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width={32} style={{verticalAlign: 'middle'}} />*/}
                <span className="image-text">{option.firstName} {option.lastName}</span>
            </div>
        );
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{t(option)}</span>;
    }

    const clearFilter = () => {
        initFilters();
    }

    const viewFileSignDialog = (_file) => {
        // console.log(JSON.stringify(_file));
        setFile(_file);
        setFileSignDialog(true);
    }

    const viewFileDet = async (_file) => {
        // console.log(JSON.stringify(_file));
        const fileService = new FileService();
        await fileService.getFile(authenticationService.getCurrentUser().userId, _file.fileTransactionId)
            .then(response => {
                setFile(response.data);
                // console.log(JSON.stringify(file));
            });
        setFileDetDialog(true);
    }

    const fileDetUserName = (fileDet) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title">{t('representative')}</span>*/}
                <span className="image-text">{fileDet.userFirstLastName}</span>
            </React.Fragment>
        );
    }

    const fileDetViewd = (fileDet) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title">{t('viewed')}</span>*/}
                <span className="image-text"
                      style={{color: fileDet.readTransactionId ?
                        (fileDet.signAllowed) ? 'green' : 'black'
                        : 'red'}}
                      tooltip={fileDet.readTransactionId ? fileDet.readTransactionId : ''} tooltipOptions={{position: 'bottom'}}>
                    {fileDet.readTransactionId ? t('yes') : t('no')}
                    {fileDet.readTransactionId ? 
                        ' '+ t('on') +' ' + dateFormat(fileDet.readStamp, DATE_FORMAT)
                        : ''}
                    <br/>
                    {fileDet.readTransactionId ? t('views') + ' ' + fileDet.readCount : ''}
                </span>
            </React.Fragment>
        );
    }

    const fileDetSigned = (fileDet) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title">{t('signed')}</span>*/}
                <span className="image-text"
                      style={{color: fileDet.signTransactionId ?
                              (fileDet.signAllowed) ? 'green' : 'black'
                              : 'red'}}>
                    {fileDet.signTransactionId ? t('yes') : (fileDet.signAllowed) ? t('no') : t('notforsign')}
                    {fileDet.signTransactionId ? ' '+ t('on') +' ' + dateFormat(fileDet.signStamp, DATE_FORMAT) : ''}
                </span>
            </React.Fragment>
        );
    }

    const hideFileSignDialog = () => {
        LoadFileList();
        // setSubmitted(false);
        setFileSignDialog(false);
    }

    const hideFileDetDialog = () => {
        LoadFileList();
        setFileDetDialog(false);
    }

    const actionBodyTemplate = (rowData) => {
        let signButton;
        let viewButton;
        if (rowData.signAllowed && rowData.signTransactionId === null) {
            signButton = <Button type="button" icon="pi pi-check" className="p-button-secondary"
                                 onClick={() => viewFileSignDialog(rowData)}
                                 tooltip={t("sign_document")} tooltipOptions={{position: 'top'}}/>
        }
        if (rowData.fileName.includes('.pdf')) {
            viewButton =
                <Button type="button" icon="pi pi-eye" className="p-button-info"
                        onClick={() => pdfPreviewBlock(rowData)}
                        tooltip={t('view_document')} tooltipOptions={{position: 'top'}}
                        style={{marginRight: '2px'}}/>
        }
        return (
            <div>
                {viewButton}
                <Button type="button" icon="pi pi-download" className="p-button-warning"
                        onClick={() => downloadFile(rowData)}
                        tooltip={t('download_document')} tooltipOptions={{position: 'top'}}
                        style={{marginRight: '2px'}}/>
                <Button type="button" icon="pi pi-info-circle" className="p-button-help"
                        onClick={() => viewFileDet(rowData)}
                        tooltip={t('document_details')} tooltipOptions={{position: 'top'}}
                        style={{marginRight: '2px'}}/>
                {signButton}
            </div>
        );
    }

    const fileSignDialogFooter = (
        <React.Fragment>
            <div style={{textAlign: 'center'}}>
                <h4>{t('scan_with_mobile')}</h4>
            </div>
        </React.Fragment>
    );
    const fileDetDialogFooter = (
        <React.Fragment>
            <div style={{textAlign: 'center'}}>
                {/*<h4>{t('scan_with_mobile')}</h4>*/}
            </div>
        </React.Fragment>
    );

    const reset = () => {
        setSelectedRepresentative(null);
        setSelectedDate(null);
        setSelectedStatus(null);
        // setGlobalFilter('');
        dt.current.reset();
    }

    const header = (
        <div className="table-header" style={{height: '30px'}}>
            {t('users')}
            <Button type="button" icon="pi pi-filter-slash" label={t('clear')} className="p-button-outlined"
                    onClick={clearFilter} style={{float:'right', verticalAlign:'top'}}/>
        </div>
    );

    const detHeader = (
        <div className="table-header">
            {t('stats')}
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <React.Fragment>
                {/*<span className="p-column-title">{t('file_name')}</span>*/}
                <span>{data.fileName}</span>
            </React.Fragment>
        );
    };

    const pdfPreviewBlock = async (file) => {
        const fileService = new FileService();
        await fileService.viewFile(authenticationService.getCurrentUser().userId, file.fileTransactionId)
            .then(response => {
                var fileBlob = b64toBlob(response.data.documentPDF, 'application/pdf');
                const fileURL = URL.createObjectURL(fileBlob);
                window.open(fileURL);
            });
    }

    const downloadFile = async (file) => {
        const fileService = new FileService();
        await fileService.viewFile(authenticationService.getCurrentUser().userId, file.fileTransactionId)
            .then(
                response => {
                    var fileBlob = b64toBlob(response.data.documentPDF, 'application/pdf');
                    const fileURL = URL.createObjectURL(fileBlob);
                    saveAs(
                        fileURL,
                        response.data.name
                    );
                }
            );
    }

    const directionBody = (rowData) => {
        const amI = (rowData.fileUserId === authenticationService.getCurrentUser().userId);
        return (
            <div>
                <i className={amI ? "pi pi-arrow-left" : "pi pi-arrow-right"}
                   style={amI ? {color: 'red'} : {color: 'green'}} title={amI ? "Out" : "In"}/>
            </div>
        );
    };

    function qrCodeBody(data) {
        if (data.fileTransactionId != null) {
            return (
                <div>
                    <QRCode value={file.fileTransactionId} style={{width: '40vm'}}/>
                    <br/>
                    <h5 htmlFor='name'>{t('block_reference')}</h5>
                    <label id="name" >{file.fileTransactionId}</label>
                </div>
            );
        } else {
            return (
                <div>
                    <label>Refference code not generated yet</label>
                </div>
            );
        }
    };


    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    const dateFilter = options => {
        return (
            <Calendar value={selectedDate} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="dd.mm.yy"
                         className="p-column-filter" placeholder={t('creation_date')}
                         readOnlyInput/>
        )
    }

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value}
                         onChange={(e) => options.filterCallback(e.value, options.index)}
                         dateFormat="dd.mm.yy" placeholder="dd.mm.yyyy" mask="99.99.9999" />
    }

    const representativeFilter = <MultiSelect value={selectedRepresentative} options={userList}
                                              itemTemplate={representativesItemTemplate} filter
                                              onChange={onRepresentativesChange} optionLabel="firstLastName"
                                              optionValue="userId" placeholder={t('all')}
                                              className="p-column-filter"/>;
    const statusFilter =  (options) => {
        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)}
                                   itemTemplate={statusItemTemplate} placeholder={t('all')}
                                   className="p-column-filter" showClear/>
        );
    }
    return (
        <div className="p-col-12">
            <div className="datatable-responsive">
                <div className="card">
                    <h5>{t('uploaded_files')}</h5>
                    <DataTable value={fileList} paginator className="files-datatable" rows={10} dataKey="fileId"
                               rowHover selection={selectedFile} onSelectionChange={(e) => setSelectedFile(e.value)}
                               emptyMessage="No files found." header={header} ref={dt} showGridlines
                               filters={filters}>
                        <Column header={t('direction')} body={directionBody}/>

                        <Column field="fileCreatedOn" header={t('date')} body={dateBodyTemplate} sortable
                                filterElement={dateFilterTemplate} filterField="fileCreatedOn" dataType="date" />

                        <Column field="fileName" header={t('file_name')} sortable filter
                                filterPlaceholder={t('search_by_name')}
                                filterMatchMode="contains" body={bodyTemplate}/>

                        <Column field="fileUserId" className="p-column-title"
                                header={t('representative')} sortable filter
                                filterElement={representativeFilter} body={representativeBodyTemplate}/>

                        <Column field="status" header={t('status')} body={statusBodyTemplate} sortable filter
                                filterElement={statusFilter} />

                        <Column body={actionBodyTemplate}
                                bodyStyle={{textAlign: 'left', overflow: 'visible'}}/>
                    </DataTable>

                    <Dialog visible={fileSignDialog} style={{ width: '600px', textAlign: 'center', maxWidth: '100%'}}
                            header={t('file_signing')} modal
                            className="p-fluid" footer={fileSignDialogFooter} onHide={hideFileSignDialog}>
                        <div className="p-field">
                            <h5 htmlFor="name">{t('file_name')} :</h5>
                            <h3 id="name">{file.fileName}</h3>
                            <br/>
                            <h5 htmlFor="name">{t('sign_code')} :</h5>
                            {qrCodeBody(file)}
                        </div>
                    </Dialog>
                    <Dialog visible={fileDetDialog} style={{ width: '500px', textAlign: 'center', maxWidth: '100%'}}
                            header={t('document_details')} modal
                            className="p-fluid" footer={fileDetDialogFooter} onHide={hideFileDetDialog}>
                        <div className="p-field">
                            <h5 htmlFor="name">{t('file_name')} :</h5>
                            <h3 id="name">{file.name}</h3>
                            <br/>
                            <div className="datatable-responsive">
                                <div className="card">
                                    <DataTable ref={fdt} value={file.userFileList} paginator rows={10}
                                               header={detHeader} className="files-datatable"
                                               emptyMessage="No customers found." GridLines>
                                        <Column field="fileUserId" className="p-column-title"
                                                header={t('representative')} sortable
                                                filterElement={representativeFilter} body={fileDetUserName}/>
                                        <Column field="viewed" className="p-column-title" header={t('viewed')}
                                                body={fileDetViewd} sortable/>
                                        <Column field="signed" className="p-column-title" header={t('signed')}
                                                body={fileDetSigned} sortable/>

                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}