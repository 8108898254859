import React from 'react';
import {BASE_DOMAIN} from './constants/Constants';
import packageJson from '../package.json';
import {useTranslation} from "react-i18next";

const AppFooter = () => {
    const { t } = useTranslation();

    return (
        <div className="layout-footer">
            <div>
                <img src="assets/layout/images/grey_eng_footer.png" alt="TIBackbone-logo" />
                <img src="assets/layout/images/logo-integrity.png" alt="integrity-logo" />
            </div>
            <div className="footer-icons">
                <button type="button" className="p-link">
                    <i className="pi pi-home"></i>
                </button>
                <button type="button" className="p-link">
                    <i className="pi pi-globe"></i>
                </button>
                <button type="button" className="p-link">
                    <i className="pi pi-envelope"></i>
                </button>
                <p>{BASE_DOMAIN}</p>
                <p>Version : {packageJson.version}</p>
            </div>
            <div className="footer-icons">
                <p>+359 2 986 77 13</p>
                <p>mailbox@business.transparency.bg</p>
                <p className=''>{t('sofia')}</p>
            </div>
        </div>
    )
}

export default AppFooter;