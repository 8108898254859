import React, {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {classNames} from 'primereact/utils';
import UserService from '../services/UserService';
import {useTranslation} from 'react-i18next';
import 'primeflex/primeflex.min.css';
import 'primereact/resources/primereact.min.css';
import '../assets/css/Form.css';
import {RECAPTCHA_SITE_KEY} from '../constants/Constants';
import {Captcha} from 'primereact/captcha';

export const ForgotPassword = () => {
	const { t } = useTranslation();
	const [displayBasic, setDisplayBasic] = useState(false);
	const [captchaVerified, setCaptchaVerified] = useState(null);
	const [dissabledBtn, setDissabledBtn] = useState(false);
	const defaultValues = {
		mail: '',
		captcha: ''
	};
	const showResponse = (response) => {
		setCaptchaVerified(response);
	}

	const [statusImage, setStatusImage] = useState(null);
	const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });
	const onHide = (name) => {
		dialogFuncMap[`${name}`](false);
	};

	const onSubmit = async (data) => {
		if (captchaVerified){
			setDissabledBtn(true);
			const userService = new UserService();
			await userService.forgotPassword(data)
				.then( result => {
						setStatusImage("assets/layout/images/transaction_successful.gif");
						setCaptchaVerified(result.data.message);
					}
				)
				.catch( () =>{
						setStatusImage("assets/layout/images/transaction_failed.gif");
						setCaptchaVerified("Не намерихме Вашият мейл в нашата система. Моля проверете и опитайте отново");
					}
				);
			dialogFuncMap['displayBasic'](true);
			setDissabledBtn(false);
		}
	};

	const dialogFuncMap = {
		'displayBasic': setDisplayBasic
	}

	const getFormErrorMessage = (name) => {
		return errors[name] && <small className="p-error">{errors[name].message}</small>
	};

	return (
		<div className="form-login">
			<script src="https://www.google.com/recaptcha/api.js?render=explicit" async defer></script>
			<div className="p-d-flex p-jc-center">
				<div className="card">
					<div className="text-center mb-5">
						<img src={t('logo_vertical_url')} alt="hyper" height={250} className="mb-3" />
						<div className="text-900 text-2xl font-medium mb-3">{t('forgot_password')}</div>
						<a className="no-underline ml-2 apply-button cursor-pointer line-height-3" href="/login">{t('sign_in')}</a>
						<a className="apply-button no-underline ml-2 cursor-pointer line-height-3" href="/register">{t('create_today')}</a>
					</div>
					<form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
						<div className="p-field width94">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />
                                <Controller name="mail" control={control}
											rules={{ required: t('email_required'),
												pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Invalid email address. E.g. example@email.com' }}}
											render={({ field, fieldState }) => (
												<InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} keyfilter="email" />
											)} />
                                <label htmlFor="mail" className={classNames({ 'p-error': !!errors.mail })}>Email*</label>
                            </span>
							{getFormErrorMessage('mail')}
						</div>

						<div className="recaptcha" style={{textAlign: "center;"}}>
							<Captcha siteKey={RECAPTCHA_SITE_KEY} onResponse={showResponse} style={{margin: "auto"}}/>
						</div>
						<div style={{height: "25px"}}/>
						<div style={{textAlign: "center"}}>
							<Button type="submit" label={t('password_request')} icon="pi pi-user" className="p-button-success"
							dissabled={dissabledBtn} style={{width:"100%", borderRadius:0}}/>
						</div>
					</form>
					<Dialog header={captchaVerified} visible={displayBasic}
							footer="  " style={{ width: '40vw', textAlign: 'center'}} onHide={() => onHide('displayBasic')}>
						<img src={statusImage} width="60%"/>
					</Dialog>
				</div>
			</div>
		</div>
	);
}