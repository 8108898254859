import {BASE_DOMAIN} from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

export default class FileService {

    async getFileList(userId){
        return await api
            .get( BASE_DOMAIN + 'api/file/list', {headers: Object.assign(authHeader() ,{  userId : userId })} );
    }

    async getAllFileList(){
        return await api
            .get( BASE_DOMAIN + 'api/file/all', {headers: Object.assign(authHeader())} );
    }

    async getFile(userId, fileTransactionId){
        return await api
            .get( BASE_DOMAIN + 'api/file/'+fileTransactionId, {headers: Object.assign(authHeader() ,{  userId : userId })} );
    }

    async viewFile(userId, fileTransactionId){
        return await api
            .get( BASE_DOMAIN + 'api/file/view/'+fileTransactionId,
                {headers: Object.assign(authHeader() ,{  userId : userId })} );
    }
    
    async uploadFile(userId, formData){
        return await api.post(BASE_DOMAIN + 'api/file/upload',
            formData,
            {headers: Object.assign(authHeader() ,{  userId : userId })
            });
    }

}