import React, {useEffect, useRef, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import FileService from '../services/FileService';
import UserService from '../services/UserService';
import dateFormat from 'dateformat';
import {Dialog} from 'primereact/dialog';
import {DATE_FORMAT} from '../constants/Constants';
import {useTranslation} from 'react-i18next';
import authenticationService from '../services/auth.service';
import {Messages} from 'primereact/messages';

export const UserList = props => {

    const msgs = useRef(null);
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [radioValue1, setRadioValue1] = useState('');
    const [radioValue2, setRadioValue2] = useState('');


    const {t} = useTranslation();
    const [fileList, setFileList] = useState([]);
    const [userList, setUserList] = useState([]);
    // const [selectedUser1, setSelectedUser1] = useState([]);
    const [selectedRepresentative, setSelectedRepresentative] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const emptyUser = {
        firstName: '',
        midName: '',
        lastName: '',
        mail: '',
        phone: '',
        company:''
    }

    const [userDetShown, setUserDetShown] = useState(false);
    // const [selectedUser, setSelectedUser] = useState(emptyUser);
    const [user, setUser] = useState(emptyUser);
    const dt = useRef(null);
    const fdt = useRef(null);

    const statuses = [
        'ACTIVE', 'INACTIVE', 'BLOCKED'
    ];

     useEffect(async () => {
        const userService = new UserService();
        await userService.getUserList(authenticationService.getCurrentUser().userId, true)
            .then(response => {
                    setUserList(response.data)
                    // console.log(JSON.stringify(data));
                }
            );
    }, []);

    useEffect(() => {
        const fileService = new FileService();
        fileService.getFileList(authenticationService.getCurrentUser().userId)
            .then(response => setFileList(response.data));
    }, []);

    function LoadUserList() {
        const userService = new UserService();
        userService.getUserList(authenticationService.getCurrentUser().userId, true)
            .then(response => setUserList(response.data));
    }

    const filterDate = (value, filter) => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }

        return value === formatDate(filter);
    }

    const formatDate = (date) => {
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return date.getFullYear() + '-' + month + '-' + day;
    }

    const onRepresentativesChange = (e) => {
        dt.current.filter(e.value, 'fileUserId', 'in');
        setSelectedRepresentative(e.value);
    }

    const onDateChange = (e) => {
        dt.current.filter(e.value, 'date', 'custom');
        setSelectedDate(e.value);
    }

    const onStatusChange = (e) => {
        dt.current.filter(e.value, 'status', 'equals');
        setSelectedStatus(e.value);
    }

    const representativeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">{t('representative')}</span>
                <span className="image-text">{rowData.fileUserFirstLastName}</span>
            </React.Fragment>
        );
    }

    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">{t('date')}</span>
                <span>{dateFormat(rowData.fileCreatedOn, DATE_FORMAT)}</span>
            </React.Fragment>
        );
    }

    const statusBody = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">{t('status')}</span>
                <span className={`customer-badge status-${rowData.status}`}
                      style={{color: rowData.status === 'ACTIVE' ? 'green' : 'red'}}>
                    {t(rowData.status)}
                </span>
            </React.Fragment>
        );
    }

    const statusItemTemplate = (option) => {
        return <span className={`customer-badge status-${option}`}>{t(option)}</span>;
    }

    const addMessages = (severity, summary, detail) => {
        msgs.current.show([
            { severity: severity, summary: summary, detail: ". "+detail, sticky: false, life: 5000 }
        ]);
    }

    const activateUser = async (userData) => {
        const userService = new UserService();
        await userService.activateUser(authenticationService.getCurrentUser().userId, userData.mail)
            .then(response => {
                addMessages('success', 'Успех!', response.data.message);
            }).catch(error => {
                // console.log(JSON.stringify(error));
                addMessages('error', 'Възникна грешка ', error.response.data.message);
            });
        LoadUserList();
    }

    const deactivateUser = async (userData) => {
        const userService = new UserService();
        await userService.deactivateUser(authenticationService.getCurrentUser().userId, userData.mail)
            .then(response => {
                addMessages('success', 'Успех!', response.data.message);
            }).catch(error => {
                // console.log(JSON.stringify(error));
                addMessages('error', 'Възникна грешка ', error.response.data.message);
            });
        LoadUserList();
    }

    const hideFileDetDialog = () => {
        LoadUserList();
        setUserDetShown(false);
    }

    const editUser = (rowData) => {
        setUser({...rowData});
        setUserDetShown(true);
    }

    const rowClick = (event) => {
        setUser({...event.data});
        setUserDetShown(true);
    }
    
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = {...user};
        _user[`${name}`] = val;

        setUser(_user);
    }

    const actionBodyTemplate = (rowData) => {
        let viewButton;
        if (rowData.status === 'INACTIVE') {
            viewButton =
                <Button type="button" icon="pi pi-check" className="p-button-success"
                        onClick={() => activateUser(rowData)}
                        tooltip={t('activate')} tooltipOptions={{position: 'top'}}
                        style={{marginRight: '2px'}}/>
        }
        if (rowData.status === 'ACTIVE') {
            viewButton =
                <Button type="button" icon="pi pi-times" className="p-button-help"
                        onClick={() => deactivateUser(rowData)}
                        tooltip={t('deactivate')} tooltipOptions={{position: 'top'}}
                        style={{marginRight: '2px'}}/>
        }
        return (
            <div>
                <Button type="button" icon="pi pi-info-circle" className="p-button-warning"
                        onClick={() => editUser(rowData)}
                        tooltip={t('user_edit')} tooltipOptions={{position: 'top'}}
                        style={{marginRight: '2px'}}/>
                {viewButton}
            </div>
        );
    }

    const onDialogClose = () => {
        setUserDetShown(false);
    }

    const onSave = async () => {
        const userService = new UserService();
        await userService.updateUser(authenticationService.getCurrentUser().userId, user);
        LoadUserList();
        onDialogClose();
    }

    const nameBody = (data, props) => {
        return (
            <React.Fragment>
                <span className="p-column-title">{t('name')}</span>
                <span>{data.firstLastName}</span>
            </React.Fragment>
        );
    };

    const userDetDialogFooter = () => {
        return (
            <div style={{textAlign: "center"}}>
                <Button label={t('save')} icon="pi pi-check" className="p-button-success" onClick={onSave}/>
                <Button label={t('cancel')} icon="pi pi-times" className="p-button-help" onClick={onDialogClose}/>
            </div>
        )
    }

    const dateFilter = <Calendar value={selectedDate} onChange={onDateChange} dateFormat="yy-mm-dd"
                                 className="p-column-filter" placeholder={t('creation_date')}/>;

    const statusFilter = <Dropdown value={selectedStatus} options={statuses} onChange={onStatusChange}
                                   itemTemplate={statusItemTemplate} placeholder={t('all')}
                                   className="p-column-filter" showClear/>;
    return (
        <div className="p-col-12">
            <div className="datatable-responsive">
                <div className="card">
                    <Messages ref={msgs} />
                    <h5>{t('users')}</h5>
                    <DataTable value={userList} paginator className="users-datatable" rows={10} dataKey="userId"
                               rowHover selection={user} onSelectionChange={(e) => setUser(e.value)}
                               emptyMessage="No Users found." ref={dt} onRowDoubleClick={event => rowClick(event)}>

                        <Column field="firstLastName" header={t('name')} sortable filter
                                filterPlaceholder={t('search_by_name')}
                                filterMatchMode="contains" body={nameBody}/>

                        <Column field="company" header={t('company')} sortable filter
                                filterPlaceholder={t('search_by_company')}
                                filterMatchMode="contains"/>

                        <Column field="mail" header={t('email')} sortable filter
                                filterPlaceholder={t('search_by_mail')}
                                filterMatchMode="contains"/>

                        <Column field="phone" header={t('phone')} sortable filter
                                filterPlaceholder={t('search_by_phone')}
                                filterMatchMode="contains"/>

                        <Column field="status" header={t('status')} body={statusBody} sortable filter
                                filterElement={statusFilter}/>

                        <Column body={actionBodyTemplate}
                                bodyStyle={{textAlign: 'left', overflow: 'visible'}}/>
                    </DataTable>

                    <Dialog visible={userDetShown} style={{width: '50vw', textAlign: 'center'}}
                            header={t('user_edit')} modal
                            className="p-fluid" footer={userDetDialogFooter} onHide={hideFileDetDialog}>
                        <div className="card">
                            <div className="p-fluid p-grid">

                                <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-user"/>
                                        <InputText id="first_name" type="text" value={user.firstName}
                                                   onChange={(e) => onInputChange( e, 'firstName' )}/>
                                        <label htmlFor="first_name">{t('first_name')}</label>
                                    </span>
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-user"/>
                                        <InputText id="mid_name" type="text" value={user.midName}
                                                   onChange={(e) => onInputChange( e, 'midName' )}/>
                                        <label htmlFor="mid_name">{t('mid_name')}</label>
                                    </span>
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-user"/>
                                        <InputText id="last_name" type="text" value={user.lastName}
                                                   onChange={(e) => onInputChange( e, 'lastName' )}/>
                                        <label htmlFor="last_name">{t('last_name')}</label>
                                    </span>
                                </div>

                                <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-envelope"/>
                                            <InputText id="mail" type="text" value={user.mail} keyfilter="email"
                                                       onChange={(e) => onInputChange( e, 'mail' )}/>
                                        <label htmlFor="mail">{t('email')}</label>
                                    </span>
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-phone" />
                                            <InputText id="phone" type="text" value={user.phone} keyfilter="num"
                                                       onChange={(e) => onInputChange( e, 'phone' )}/>
                                        <label htmlFor="phone" >{t('phone')}*</label>
                                    </span>
                                </div>
                                <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-home" />
                                            <InputText id="company" type="text" value={user.company}
                                                       onChange={(e) => onInputChange( e, 'company' )}/>
                                        <label htmlFor="company" >{t('company')}*</label>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}