import React from 'react';
import {Route, withRouter} from 'react-router-dom';
import App from './App';
import {Login} from './pages/Login';
import {Register} from './pages/Register';
import {Privacy} from './pages/Privacy';
import {ForgotPassword} from './pages/ForgotPassword';
import {PasswordReset} from './pages/PasswordReset';
import {Error} from './pages/Error';
import {NotFound} from './pages/NotFound';
import {Access} from './pages/Access';

const AppWrapper = (props) => {
	if (props.location.pathname.indexOf('/passwordReset') > -1){
		return <Route path="/passwordReset/:token/:uuid" component={PasswordReset} />
	}
	if (props.location.pathname.indexOf('/app.apk') > -1){
		return ;
	}
	switch (props.location.pathname) {
		case '/login':
			return <Route path="/login" component={Login} />
		case '/privacy':
			return <Route path="/privacy" component={Privacy} />
		case '/register':
			return <Route path="/register" component={Register} />
		case '/forgotPassword':
			return <Route path="/forgotPassword" component={ForgotPassword} />
		case '/error':
			return <Route path="/error" component={Error} />
		case '/notfound':
			return <Route path="/notfound" component={NotFound} />
		case '/access':
			return <Route path="/access" component={Access} />
		default:
			return <App />;
	}

}

export default withRouter(AppWrapper);