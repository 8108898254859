import React, {useState} from 'react';
import classNames from 'classnames';
import {useHistory} from 'react-router-dom';
import LanguageSelect from "./languageSelect";
import {Password} from 'primereact/password';
import {useTranslation} from 'react-i18next';
import authenticationService from './services/auth.service';
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import UserService from "./services/UserService";
import {Divider} from "primereact/divider";

const AppTopbar = (props) => {

    const [settingsDialogShow, setSettingsDialogShow] = useState(false);
    const [changePassDialogShow, setChangePassDialogShow] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    let user = authenticationService.getCurrentUser();

    const emptyUser = {
        firstName: '',
        midName: '',
        lastName: '',
        mail: '',
        phone: '',
        company:'',
        token:'',
        tokenStamp:'',
        // oldPassword:'',
        password:''
        // rePassword:''
    }

    const [activeUser, setActiveUser] = useState(emptyUser);

    const loadUser = async () => {
        const userService = new UserService();
        await userService.getUser(authenticationService.getCurrentUser().token, user.userId)
            .then(result => setActiveUser(result.data));
    }

    const onTopbarItemClick = (event, item) => {
        if (props.onTopbarItemClick) {
            props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    const logout = () => {
        authenticationService.logout();
    }

    const openChangePassword = async () => {
        await loadUser();
        setChangePassDialogShow(true);
    }

    const closeChangePassword = () => {
        setChangePassDialogShow(false);
    }

    const changePassword = async () => {
        const userService = new UserService();
        await userService.loginUser(activeUser.mail, activeUser.oldPassword)
            .then( () => {
            userService.passwordReset(activeUser);
        }
        ).catch(err => {
            console.log(err);
        })
        closeChangePassword();
    }

    const openSettings = async () => {
        await loadUser();
        setSettingsDialogShow(true);
    }

    const closeSettings = () => {
        setSettingsDialogShow(false);
    }

    const saveSettings = async () => {

        const userService = new UserService();
        await userService.updateUser(authenticationService.getCurrentUser().userId, activeUser);
        authenticationService.updateStoredUser(activeUser);
        closeSettings();
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = {...activeUser};
        _user[`${name}`] = val;

        setActiveUser(_user);
    }

    const settingsDialogFooter = () => {
        return (
            <div style={{textAlign: "center"}}>
                <Button label={t('save')} icon="pi pi-check" className="p-button-success" onClick={saveSettings}/>
                <Button label={t('cancel')} icon="pi pi-times" className="p-button-help" onClick={closeSettings}/>
            </div>
        )
    }

    const passwordDialogFooter = () => {
        return (
            <div style={{textAlign: "center"}}>
                <Button label={t('save')} icon="pi pi-check" className="p-button-success" onClick={changePassword}/>
                <Button label={t('cancel')} icon="pi pi-times" className="p-button-help" onClick={closeChangePassword}/>
            </div>
        )
    }

    const passwordHeader = <h6>{t('pick_password')}</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="p-mt-2">{t('suggestions')}</p>
            <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: '1.5' }}>
                <li>{t('alo_lowercase')}</li>
                <li>{t('alo_uppercase')}</li>
                <li>{t('alo_numeric')}</li>
                <li>{t('alo_eight')}</li>
            </ul>
        </React.Fragment>
    );

    return (
        <div className="layout-topbar">

            <button type="button" className="p-link layout-menu-button layout-topbar-icon" onClick={props.onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            <button type="button" className="p-link layout-topbar-logo" onClick={() => history.push('/')}>
                <img id="topbar-logo" src={t('logo_url')} alt="roma-react" />
            </button>

            <ul className="topbar-menu">
                <li className={classNames('user-profile', { 'active-topmenuitem fadeInDown': props.activeTopbarItem === 'profile' })}>
                    <button type="button" className="p-link layout-topbar-icon" onClick={(e) => onTopbarItemClick(e, 'profile')}>
                        <i className="topbar-icon pi pi-fw pi-user"> </i>
                        <span className="layout-profile-name"> {(user) ? user.firstName + ' ' +user.lastName : ''} </span>
                    </button>
                    <ul className="fadeInDown">
                        <li role="menuitem">
                            <LanguageSelect showText="true"/>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link" onClick={openChangePassword}>
                                <i className="pi pi-fw pi-key"></i>
                                <span> {t('change_password')}</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button type="button" className="p-link" onClick={openSettings}>
                                <i className="pi pi-fw pi-cog"></i>
                                <span> {t('settings')}</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button onClick={logout} type="button" className="p-link">
                                <i className="pi pi-fw pi-lock"></i>
                                <span> {t('logout')}</span>
                            </button>
                        </li>
                    </ul>
                </li>

                {/*<li className={classNames({ 'active-topmenuitem fadeInDown': props.activeTopbarItem === 'settings' })}>*/}
                {/*    <button type="button" className="p-link layout-topbar-icon" onClick={(e) => onTopbarItemClick(e, 'settings')}>*/}
                {/*        <i className="topbar-icon pi pi-fw pi-bell"></i>*/}
                {/*    </button>*/}
                {/*    <ul className="fadeInDown">*/}
                {/*    </ul>*/}
                {/*</li>*/}
            </ul>
            
            <Dialog visible={settingsDialogShow} style={{width: '50vw', textAlign: 'center'}}
                    header={t('user_edit')} modal
                    className="p-fluid" footer={settingsDialogFooter} onHide={closeSettings}>
                <div className="card">
                    <div className="p-fluid p-grid">

                        <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-user"/>
                                        <InputText id="first_name" type="text" value={activeUser.firstName}
                                                   onChange={(e) => onInputChange( e, 'firstName' )}/>
                                        <label htmlFor="first_name">{t('first_name')}</label>
                                    </span>
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-user"/>
                                        <InputText id="mid_name" type="text" value={activeUser.midName}
                                                   onChange={(e) => onInputChange( e, 'midName' )}/>
                                        <label htmlFor="mid_name">{t('mid_name')}</label>
                                    </span>
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-user"/>
                                        <InputText id="last_name" type="text" value={activeUser.lastName}
                                                   onChange={(e) => onInputChange( e, 'lastName' )}/>
                                        <label htmlFor="last_name">{t('last_name')}</label>
                                    </span>
                        </div>

                        <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-envelope"/>
                                            <InputText id="mail" type="text" value={activeUser.mail}
                                                keyfilter="email" onChange={(e) => onInputChange( e, 'mail' )}/>
                                        <label htmlFor="mail">{t('email')}</label>
                                    </span>
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-phone" />
                                            <InputText id="phone" type="text" value={activeUser.phone}
                                               keyfilter="num" onChange={(e) => onInputChange( e, 'phone' )}/>
                                        <label htmlFor="phone" >{t('phone')}*</label>
                                    </span>
                        </div>
                        <div className="p-field p-col-12 p-md-4">
                                    <span className="p-float-label p-input-icon-left">
                                        <i className="pi pi-home" />
                                            <InputText id="company" type="text" value={activeUser.company}
                                                       onChange={(e) => onInputChange( e, 'company' )}/>
                                        <label htmlFor="company" >{t('company')}*</label>
                                    </span>
                        </div>

                    </div>
                </div>
            </Dialog>

            <Dialog visible={changePassDialogShow} style={{width: '30vw', textAlign: 'center'}}
                    header={t('change_password')} modal
                    className="p-fluid" footer={passwordDialogFooter} onHide={closeChangePassword}>
                <div className="card">
                    <div className="p-fluid">

                        <div className="p-field p-col-12" style={{paddingBottom: '20px'}}>
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-key"/>
                                <Password id="old_password" type="text" value={activeUser.oldPassword} toggleMask
                                          feedback={false} onChange={(e) => onInputChange( e, 'oldPassword' )}/>
                                <label htmlFor="old_password">{t('old_password')}</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12" style={{paddingBottom: '5px'}}>
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-key"/>
                                <Password id="password" type="text" value={activeUser.password}
                                          header={passwordHeader} footer={passwordFooter} toggleMask
                                          feedback={true} onChange={(e) => onInputChange( e, 'password' )}/>
                                <label htmlFor="password">{t('password')}</label>
                            </span>
                        </div>
                        <div className="p-field p-col-12">
                            <span className="p-float-label p-input-icon-left">
                                <i className="pi pi-key"/>
                                <Password id="re-password" type="text" value={activeUser.rePassword} toggleMask
                                          feedback={false} onChange={(e) => onInputChange( e, 'rePassword' )}/>
                                <label htmlFor="re-password">{t('re-password')}</label>
                            </span>
                        </div>

                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default AppTopbar;