import React from "react";
import i18next from "i18next";
import Flag from "react-flags";

const languageMap = {
    bg: { name: "Български", code: "bg" },
    en: { name: "English", code: "en" }
}
;

const LanguageSelect = (props) => {
    const selected = localStorage.getItem("i18nextLng") || "bg";

    const [menuAnchor, setMenuAnchor] = React.useState(null);
    React.useEffect(() => {
        document.body.dir = languageMap[selected].code;
    }, [menuAnchor, selected]);

    return (
        <div>
            <button type="button" className="p-link lang-btn" onClick={() => i18next.changeLanguage('bg') }>
                <Flag
                    name="BG"
                    format="png"
                    pngSize={64}
                    shiny={true}
                    alt="Bulgarian Flag"
                />
                <span style={props.showText == "true" ? {} : {display: "none"}}> BG</span>
            </button>
            <button type="button" className="p-link lang-btn" onClick={() => i18next.changeLanguage('en') }>
                <Flag
                    name="US"
                    format="png"
                    pngSize={64}
                    shiny={true}
                    alt="US Flag"
                />
                <span style={props.showText == "true" ? {} : {display: "none"}}> EN</span>
            </button>
        </div>
    );
};

export default LanguageSelect;
