import {BASE_DOMAIN} from '../constants/Constants';
import authHeader from "./AuthHeader";
import api from "./api";

export default class UserService {

    getUserList(userId, withInactive = false) {
        return api.get( BASE_DOMAIN + 'api/user/list', {
            headers:  Object.assign(authHeader(), {withInactive: withInactive, userId : userId})
            });
    }

    async loginUser(username, password){
        // console.log(username, password);
         return await api.post( BASE_DOMAIN + 'api/auth/signin', { username:username, password:password },
            { headers:{ 'Content-Type': 'application/json' } });
    }

    async registerUser(user){
        await api.post( BASE_DOMAIN + 'api/auth/signup', user,
            { headers:{ 'Content-Type': 'application/json' } });
    }

    forgotPassword(user){
        return api.post( BASE_DOMAIN + 'api/auth/forgotPassword', user);
    }
    
    async findUserByTokenAndStamp(token, stamp){
        const data = {
            token: token,
            uuid: stamp
        }
        return await api.post( BASE_DOMAIN + 'api/auth/findForPasswordReset', data);
    }

    async passwordReset(data){
        return await api.post( BASE_DOMAIN + 'api/auth/changePassword', data);
    }

    async activateUser(userId, userMail){
        return api.post( BASE_DOMAIN + 'api/user/activate', {mail: userMail}, {
            headers: Object.assign(authHeader(), {userId: userId})
        });
    }

    async deactivateUser(userId, userMail){
        return api.post( BASE_DOMAIN + 'api/user/deactivate', {mail: userMail}, {
            headers: Object.assign(authHeader(), {userId: userId})
        });
    }

    async updateUser(userId, user){
        return api.post( BASE_DOMAIN + 'api/user/update', user, {
            headers: Object.assign(authHeader(), {userId: userId})
        });
    }

    async getUser(userToken, userId){
        return api.get( BASE_DOMAIN + 'api/user/'+ userId, {
            headers: authHeader()
        });
    }

    async refreshToken(refreshToken){
        return await api.post( BASE_DOMAIN + 'api/auth/changePassword',
            {"refreshToken": refreshToken});
    }
}