import {useTranslation} from 'react-i18next';
import AuthService from '../services/auth.service';
import React, {useRef, useState} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Password} from 'primereact/password';
import 'primeflex/primeflex.min.css';
import 'primereact/resources/primereact.min.css';
import '../assets/css/Form.css';
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import LanguageSelect from "../languageSelect";

const required = (value) => {
	if (!value) {
		return (
			<div className="alert alert-danger" role="alert">
				This field is required!
			</div>
		);
	}
};

export const Login = props => {

	const { t } = useTranslation();
	const form = useRef();
	const checkBtn = useRef();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");

	const onChangeUsername = (e) => {
		setUsername(e.target.value);
	};

	const onChangePassword = (e) => {
		setPassword(e.target.value);
	};

	const handleLogin = async (e) => {
		e.preventDefault();

		setMessage("");
		setLoading(true);

		form.current.validateAll();

		if (checkBtn.current.context._errors.length === 0) {
			await AuthService.login(username, password)
				.then(() => {
					props.history.push("/");
					window.location.reload();
				},
					(error) => {
						console.log("liginpage -> error " + error);

						setLoading(false);
						setMessage(t('wrong_username_or_password'));
					}
				);
		} else {
			setLoading(false);
		}
	};

	return (
		<div className="form-login">
			<div className="p-d-flex p-jc-center">
				<div className="card">
					<div className="text-center mb-5">
						<img src={t('logo_vertical_url')} alt="hyper" height={250} className="mb-3" />
						<div className="text-600 text-2xl font-medium mb-3">{t('welcome')}</div>
						<a className="apply-button font-medium no-underline ml-2 cursor-pointer line-height-3" href="/register">{t('create_today')}</a>

						{message && (
							<div className="form-group">
								<div style={{color: "red", paddingTop:"20px"}} role="alert">
									<strong>{message}</strong>
								</div>
							</div>
						)}
					</div>
					<Form onSubmit={handleLogin} className="p-fluid" ref={form}>
						<div className="p-field width94">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope" />

								<InputText autoFocus type="text"
										   className="form-control"
										   name="username"
										   value={username}
										   onChange={onChangeUsername}
										   validations={[required]}
										   keyfilter="email"
										   rules={{ required: t('name_required') }}/>
								{/*style={{marginBottom:'15px'}}*/}

                                <label htmlFor="email">Email*</label>
                            </span>
						</div>
						<div className="p-field width94" >
                            <span className="p-float-label" >
								<Password toggleMask feedback={false}
									// type="password"
									className="form-control"
									name="password"
									value={password}
									onChange={onChangePassword}
									validations={[required]}/>
                                <label htmlFor="password" >Password*</label>
                            </span>
						</div>

						<div className="p-grid-page p-fluid">
							<div className="one-of-tree-column">
								<div style={{marginLeft: "1rem"}}>
									<a className="text-600 font-medium no-underline cursor-pointer line-height-3" href="/forgotPassword">{t('forgot_password')}</a>
									<div style={{height: "25px"}}/>
									<label>{t("useful_links")} :</label>
									<ol style={{marginLeft: "-25px"}}>
										<li>
											<a href="https://business.transparency.bg/" title={t("business_integrity")}>{t("business_integrity")}</a>
										</li>
										<li>
											<a href="https://business.transparency.bg/цели-на-клуба/" title={t("mission")}>{t("mission")}</a>
										</li>
										<li>
											<a href="https://business.transparency.bg/предимства-от-участието/" title={t("why_us")}>{t("why_us")}</a>
										</li>
										<li>
											<a href="https://business.transparency.bg/как-да-стана-член/" title={t("how_to_join")}>{t("how_to_join")}</a>
										</li>
										<li>
											<a href="https://business.transparency.bg/правила-на-клуба/" title={t("rules")}>{t("rules")}</a>
										</li>
									</ol>
									<div style={{width: "30%", float: "left"}}>
										<LanguageSelect showText="false"/>
									</div>
								</div>
							</div>

							<div className="one-of-tree-column" style={{textAlign:"center"}}>
								<Button type="submit" label={t('sign_in')} icon="pi pi-user" className="p-button-success" style={{width:"100%", borderRadius:0}}/>
								<br/>
								<br/>
								<a href="https://apps.apple.com/us/app/tibackbone/id1590525153" target="_blank">
									<img src="/assets/layout/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" width="100%"/>
								</a>
								<a href="https://play.google.com/store/apps/details?id=ltd.happycompany.tibackbone" target="_blank">
									<img src="/assets/layout/images/download-for-android.jpeg" width="100%"/>
								</a>
							</div>
						</div>

						<CheckButton style={{ display: "none" }} ref={checkBtn} />
					</Form>
				</div>
			</div>
		</div>
	);
}