import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Redirect, Route, useLocation} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {CSSTransition} from 'react-transition-group';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';
import AppRightMenu from './AppRightMenu';
import {useTranslation} from 'react-i18next';

import {Dashboard} from './components/Dashboard';
import {Upload} from './components/Upload';
import {UserList} from './components/UserList';
import {FileList} from './components/FileList';
import {Register} from './pages/Register';
import {ForgotPassword} from './pages/ForgotPassword';
import {PasswordReset} from './pages/PasswordReset';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import authenticationService from './services/auth.service';

const App = () => {

    const { t } = useTranslation();

    const [layoutMode, setLayoutMode] = useState('static');
    const [lightMenu, setLightMenu] = useState(true);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [isRTL, setIsRTL] = useState(false);
    const [inlineUser, setInlineUser] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [rightPanelMenuActive, setRightPanelMenuActive] = useState(null);
    const [inlineUserMenuActive, setInlineUserMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [topbarColor, setTopbarColor] = useState('layout-topbar-light');
    const [theme, setTheme] = useState('blue');
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('filled');
    const [ripple, setRipple] = useState(false);
    const menu = [
        {
            items: [
                { label: t('home'), icon: 'pi pi-fw pi-home', to:'/' },
                { label: t('upload_file'), icon: 'pi pi-fw pi-upload', to: '/upload' },
            ]
        },

        authenticationService.getCurrentUser() != null ?
            authenticationService.getCurrentUser().roles.includes('ROLE_ADMIN') ?
                {
                    label:t('administrative'),
                    items: [
                        { label: t('users'), icon: 'pi pi-fw pi-user', to: '/userList' },
                        { label: t('files'), icon: 'pi pi-fw pi-file', to: '/fileList' },
                    ]
                } : {}
            : {},
         {
            label: t('useful_links'), icon: 'pi pi-fw pi-home',
            items: [
                { label: t('business_integrity'), icon: 'pi pi-fw pi-list', url: 'https://business.transparency.bg/' },
                { label: t('mission'), icon: 'pi pi-fw pi-list', url: 'https://business.transparency.bg/цели-на-клуба/' },
                { label: t('why_us'), icon: 'pi pi-fw pi-list', url: 'https://business.transparency.bg/предимства-от-участието/' },
                { label: t('rules'), icon: 'pi pi-fw pi-list', url: 'https://business.transparency.bg/правила-на-клуба/' },
                { label: t('how_to_join'), icon: 'pi pi-fw pi-list', url: 'https://business.transparency.bg/как-да-стана-член/' },
            ],
         },
    ];
    const routers = [
        { path: '/', component: Dashboard, exact: true },
        { path: '/upload', component: Upload },
        { path: '/userList', component: UserList },
        { path: '/fileList', component: FileList },
        { path: '/register', page: Register },
        { path: '/passwordReset/:token/:stamp', page: PasswordReset },
        { path: '/forgotPassword', page: ForgotPassword }
    ];

    let topbarItemClick;
    let menuClick;
    let rightMenuClick;
    let userMenuClick;
    let configClick = false;
    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        }
        else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    const onDocumentClick = () => {
        if (!topbarItemClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false);
        }

        if (!rightMenuClick) {
            setRightPanelMenuActive(false);
        }

        if (!userMenuClick && isSlim() && !isMobile()) {
            setInlineUserMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        rightMenuClick = false;
        userMenuClick = false;
        configClick = false;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarMenuActive(false);
        setRightPanelMenuActive(false);

        if (layoutMode === 'overlay') {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        }

        if (isDesktop())
            setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
        else {
            setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
            if (staticMenuMobileActive) {
                blockBodyScroll();
            } else {
                unblockBodyScroll();
            }
        }

        event.preventDefault();
    };

    const onTopbarMenuButtonClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevTopbarMenuActive => !prevTopbarMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;

        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null);
        else
            setActiveTopbarItem(event.item);

        event.originalEvent.preventDefault();
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onInlineUserClick = () => {
        userMenuClick = true;
        setInlineUserMenuActive(prevInlineUserMenuActive => !prevInlineUserMenuActive);
        setMenuActive(false);
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive(prevConfigActive => !prevConfigActive);
        configClick = true;
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        }
        else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        }
        else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const onRightMenuButtonClick = (event) => {
        rightMenuClick = true;
        setRightPanelMenuActive(prevRightPanelMenuActive => !prevRightPanelMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
        }
        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const onRootMenuItemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
        setInlineUserMenuActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 896;
    };

    const isMobile = () => {
        return window.innerWidth <= 1025;
    };

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    };

    const isSlim = () => {
        return layoutMode === 'slim';
    };

    const onLayoutModeChange = (layoutMode) => {
        setLayoutMode(layoutMode);
        setStaticMenuDesktopInactive(false);
        setOverlayMenuActive(false);

        if (layoutMode === 'horizontal' && inlineUser) {
            setInlineUser(false)
        }

    };

    const onMenuColorChange = (menuColor) => {
        setLightMenu(menuColor);
    };

    const onThemeChange = (theme) => {
        setTheme(theme);
    };

    const onProfileModeChange = (profileMode) => {
        setInlineUser(profileMode);
    };

    const onOrientationChange = (orientation) => {
        setIsRTL(orientation);
    };

    const onTopbarColorChange = (color) => {
        setTopbarColor(color);
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-horizontal': layoutMode === 'horizontal',
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-slim': layoutMode === 'slim',
        'layout-menu-light': lightMenu,
        'layout-menu-dark': !lightMenu,
        'layout-overlay-active': overlayMenuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-static-inactive': staticMenuDesktopInactive,
        'layout-rtl': isRTL,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': !ripple
    }, topbarColor);

    const inlineUserTimeout = layoutMode === 'slim' ? 0 : { enter: 1000, exit: 450 };
    const isLogedIn = authenticationService.getCurrentUser();
    const location = useLocation();
    return isLogedIn
            // || location.pathname === '/register'
            || location.pathname === '/app.apk'
            // || location.pathname === '/forgotPassword'
            || location.pathname === '/passwordReset/:token/:stamp'
    ? (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <AppTopbar topbarMenuActive={topbarMenuActive} activeTopbarItem={activeTopbarItem} inlineUser={inlineUser}
                onRightMenuButtonClick={onRightMenuButtonClick} onMenuButtonClick={onMenuButtonClick}
                onTopbarMenuButtonClick={onTopbarMenuButtonClick} onTopbarItemClick={onTopbarItemClick} />

            <AppRightMenu rightPanelMenuActive={rightPanelMenuActive} onRightMenuClick={onRightMenuClick}></AppRightMenu>

            <div className="layout-menu-container" onClick={onMenuClick}>
                {
                    inlineUser && (
                        <div className="layout-profile">
                            <button type="button" className="p-link layout-profile-button" onClick={onInlineUserClick}>
                                <img src="assets/layout/images/avatar.png" alt="roma-layout" />
                                <div className="layout-profile-userinfo">
                                    <span className="layout-profile-name">Arlene Welch</span>
                                    <span className="layout-profile-role">Design Ops</span>
                                </div>
                            </button>
                            <CSSTransition classNames="p-toggleable-content" timeout={inlineUserTimeout} in={inlineUserMenuActive} unmountOnExit>
                                <ul className={classNames('layout-profile-menu', { 'profile-menu-active': inlineUserMenuActive })}>
                                    <li>
                                        <button type="button" className="p-link">
                                            <i className="pi pi-fw pi-user"></i><span>Profile</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" className="p-link">
                                            <i className="pi pi-fw pi-cog"></i><span>Settings</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" className="p-link">
                                            <i className="pi pi-fw pi-envelope"></i><span>Messages</span>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" className="p-link">
                                            <i className="pi pi-fw pi-bell"></i><span>Notifications</span>
                                        </button>
                                    </li>
                                </ul>
                            </CSSTransition>
                        </div>
                    )
                }
                <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} layoutMode={layoutMode} active={menuActive} mobileMenuActive={staticMenuMobileActive} />
            </div>

            <div className="layout-main">
                <Helmet>
                    <title>TI backbone</title>
                </Helmet>
                <div className="layout-content">
                    {
                        routers.map((router, index) => {
                            if (router.exact) {
                                return <Route key={`router${index}`} path={router.path} exact component={router.component} class={router.path}/>
                            }
                            if (router.class != 'p-dissabled'){
                                return <Route key={`router${index}`} path={router.path} component={router.component} />
                            }
                            
                        })
                    }
                </div>
            </div>
            <AppFooter />

            <div className="layout-content-mask"></div>
        </div>
    ) : (
        <Redirect
            to={{
                pathname: "/login",
                state: {
                    prevLocation: location.pathname,
                    error: "You need to login first!",
                },
            }}/>
    );

}

export default App;
