import 'react-app-polyfill/ie11';
import './i18nextConf';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import AppWrapper from './AppWrapper';

ReactDOM.render(
	<BrowserRouter>
		<AppWrapper></AppWrapper>
	</BrowserRouter>,
	document.getElementById('root')
);