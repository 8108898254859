import {BASE_DOMAIN} from '../constants/Constants';
import axios from "axios";
import {Redirect} from 'react-router-dom';

const register = (username, email, password) => {
        return axios.post(BASE_DOMAIN + "api/auth/signup", {
            username,
            email,
            password,
        });
    };

    async function login(username, password) {
        return await axios
            .post(BASE_DOMAIN + "api/auth/signin", {
                username,
                password,
            })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            });
    };

    const logout = () => {
        localStorage.removeItem("user");
        return <Redirect to='/login' />;
    };

    const getCurrentUser = () => {
        return (localStorage.getItem("user") == null) ? null :
            // JSON.parse("{\"userId\": 0, \"roles\":[\"ROLE_USER\"]}") :
            JSON.parse(localStorage.getItem("user"));
    };

    const getLocalRefreshToken = () =>{
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.refreshToken;
    }

    const getLocalAccessToken = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return user?.token;
    }

    const updateLocalAccessToken = (newToken) => {
        let user = JSON.parse(localStorage.getItem("user"));
        user.token = newToken;
        localStorage.setItem("user", JSON.stringify(user));
    }

    const setUserToken = (newToken) => {
        const user = JSON.parse(localStorage.getItem("user"));
        user.token = newToken;
        localStorage.setItem("user", JSON.stringify(user));
    };

    function updateStoredUser(user){
        const tempUser = JSON.parse(localStorage.getItem('user'));
        tempUser.firstName = user.firstName;
        tempUser.midName = user.midName;
        tempUser.lastName = user.lastName;
        localStorage.setItem('user', JSON.stringify(tempUser));
    }

export default {
    register,
    login,
    logout,
    getCurrentUser,
    setUserToken,
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    updateStoredUser,
};