import React, {useEffect, useRef, useState} from 'react'; // , Component
import {Dropdown} from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import {FileUpload} from 'primereact/fileupload';
import {Toast} from 'primereact/toast';
import {ProgressBar} from 'primereact/progressbar';
import {Button} from 'primereact/button';
import {Tooltip} from 'primereact/tooltip';
import {Tag} from 'primereact/tag';
import {Dialog} from 'primereact/dialog';
import QRCode from 'react-qr-code';
import UserService from '../services/UserService';
import {useTranslation} from 'react-i18next';
import authenticationService from '../services/auth.service';
import FileService from "../services/FileService";

export const Upload = props => {

    const fileSizeLimit = 2000000;
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [userList, setUserList] = useState([]);
    const [selectedFileList, setSelectedFileList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedUserList, setSelectedUserList] = useState(null);
    const [selectedSignUser, setSelectedSignUser] = useState(null);
    const [statusImage, setStatusImage] = useState(null);
    const [responseBlockRef, setResponseBlockRef] = useState("");
    const [qrCodeHolder, setQrCodeHolder] = useState("");
    const { t } = useTranslation();
    useEffect(() => {
        const userService = new UserService();
        userService.getUserList(authenticationService.getCurrentUser().token)
            .then(response => setUserList(response.data));
    }, []);

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }
    const onFileChange = (e) => {
        setSelectedFileList( e.files );
        // this.setState({ selectedFile: e.files[0] });
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }
    const onUpload = async () => {
        fileUploadRef.current.upload();
        fileUploadRef.current.clear();
    }

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        for (let i=0; i < e.files.length; i++){
            _totalSize += e.files[i].size;
        }
        if (_totalSize > fileSizeLimit){
            _totalSize = 0;
        }
        setTotalSize(_totalSize);
    }

    const onTemplateUpload = async (event) => {
        let _totalSize = 0;
        const formData = new FormData();
        event.files.forEach(file => {
            if (file) {
                formData.append("file", file, file.name);
                formData.append("canSign", selectedSignUser);
                formData.append("viewersList", selectedUserList);
                _totalSize += (file.size || 0);
            }
        });
        const fileService = new FileService();
        await fileService.uploadFile(authenticationService.getCurrentUser().userId, formData)
            .then((response) => {
                setStatusImage("assets/layout/images/transaction_successful.gif");
                if (response.data && response.data.message){
                    setQrCodeHolder(<QRCode value={response.data.message} style={{width: '40vm'}}/>);
                } else {
                    setQrCodeHolder(<h6>Възникна грешка при генериране на код</h6>);
                }
            })
            .catch((error) => {
                setStatusImage("assets/layout/images/transaction_failed.gif");
                setQrCodeHolder(<h6>{error.message}</h6>);
            });

        dialogFuncMap['displayBasic'](true);
    }

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    }

    const onTemplateClear = () => {
        setTotalSize(0);
    }

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize/10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 2 MB`} style={{width: '300px', height: '20px', marginLeft: 'auto'}}></ProgressBar>
            </div>
        );
    }

    const itemTemplate = (file, props) => {
        return (
            <div className="p-d-flex p-ai-center p-flex-wrap">
                <div className="p-d-flex p-ai-center" style={{width: '40%'}}>
                    {/*<img alt={file.name} role="presentation" src={file.objectURL} width={100} />*/}
                    <strong>{file.name}</strong>
                        {/*<td><small>{new Date().toLocaleDateString()}</small></td>*/}
                </div>
                <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger p-ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        )
    }

    const emptyTemplate = () => {
        return (
            <div className="p-d-flex p-ai-center p-dir-col">
                <i className="pi pi-image p-mt-3 p-p-5" style={{'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)'}}></i>
                <span style={{'fontSize': '1.4em', color: 'var(--text-color-secondary)', margin: "auto"}} className="p-my-6">{t('drag_n_drop')}</span>
            </div>
        )
    }

    const userOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <div>{option.firstLastName}</div>
            </div>
        );
    }

    const chooseOptions = {icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-info'};
    const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined'};
    const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'};
    return (
        <div >
            <div className="p-col-12">
                <Toast ref={toast}></Toast>

                <Tooltip target=".custom-choose-btn" content={t('choose')} position="bottom" />
                <Tooltip target=".custom-upload-btn" content={t('upload')} position="bottom" />
                <Tooltip target=".custom-cancel-btn" content={t('clear')} position="bottom" />
                <div className="card">
                    <h5>{t('file_upload')}</h5>
                    <FileUpload ref={fileUploadRef} name="tiFile[]"
                                customUpload="true" id="fileUpl"
                                multiple maxFileSize={fileSizeLimit} onSelect={onTemplateSelect}
                                uploadHandler={onTemplateUpload}  onError={onTemplateClear}
                                onClear={onTemplateClear} headerTemplate={headerTemplate} itemTemplate={itemTemplate}
                                emptyTemplate={emptyTemplate} chooseOptions={chooseOptions}
                                uploadOptions={uploadOptions} cancelOptions={cancelOptions} />

                    <Dropdown value={selectedSignUser} options={userList} onChange={(e) => setSelectedSignUser(e.value)}
                              optionLabel="firstLastName" optionValue="userId" filter showClear filterBy="firstLastName"
                              placeholder={t('choose_who_sign')} itemTemplate={userOptionTemplate} />

                    <hr/>

                    <MultiSelect value={selectedUserList} options={userList}
                                 optionLabel="firstLastName" optionValue="userId" filter showClear filterBy="firstLastName"
                                 placeholder={t('choose_who_read')} itemTemplate={userOptionTemplate}
                                 onChange={(e) => setSelectedUserList(e.value)}/>

                    <hr/>
                    <Button label={t('upload')} title={t('upload')} onClick={onUpload} className="p-button-lg p-button-info custom-upload-btn"/>
                </div>

                <Dialog header={t("sign_doc_by_scan")} visible={displayBasic}
                        footer="  " style={{ width: '500px', textAlign: 'center', maxWidth: '100%'}} onHide={() => onHide('displayBasic')}>
                    <img src={statusImage} width="50%"/>
                    <hr/>
                    {qrCodeHolder}
                </Dialog>
            </div>
        </div>
    )
}